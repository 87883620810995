import InView from 'in-view-ts';
import {Behaviour} from "./global/scripts/behaviour";

const AnimationBehaviour: Behaviour = {

    attach: function (context: HTMLElement) {

        document.documentElement.classList.add('animation-ready');
        const animationParents = context.querySelectorAll<HTMLElement>('.animationGroup');

        animationParents.forEach((animationParent) => {
            const inviewInstance = new InView();
            inviewInstance.options.offset.top = -100;
            inviewInstance.options.offset.bottom = -100;
            inviewInstance.control(animationParent).registry.on('enter', (element) => {
                element.classList.add('animationGroup--started');
            });
        });
    },
};

behaviours.addBehaviour(AnimationBehaviour);
